import {action, makeObservable, observable} from "mobx";
import {CafeAuthExisted} from "../model/cafe";
import {StoreState} from "../model/common";
import cafe24TokenRepository from "../repository/Cafe24TokenRepository";


class CafeAuthExistedStore {
    @observable
    data?: CafeAuthExisted;

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        makeObservable(this);
    }

    @action
    async load(mallId: string, state: string) {
        this.state = 'pending';
        try {
            const {data} = await cafe24TokenRepository.existedAccessToken(mallId, state);
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            if (e.response.status === 404) {
                this.state = 'done'
            } else {
                this.state = 'error';
                throw e;
            }
        }
    }

    @action
    clear() {
        this.data = undefined;
        this.state = 'none';
    }
}

export default CafeAuthExistedStore;