import React, {useMemo} from "react";
import {action, makeObservable, observable} from "mobx";
import {IntlProvider} from "react-intl";
import moment from "moment";
import {DictType} from "../model/common"
import ko from "../i18n/ko";
import en from "../i18n/en";

class I18nStore {
    @observable
    language: string = localStorage.getItem('language') || 'ko';

    constructor() {
        makeObservable(this);
    }

    @action
    setLanguage(language: string) {
        localStorage.setItem('language', language);
        moment.locale(language);
        this.language = language;
    }
}

const context = React.createContext(new I18nStore());
export const useI18nStore = () => React.useContext(context);


const I18nBoundary: React.FC = (props) => {
    const i18nStore = useI18nStore();
    const messages: DictType<Record<string, string>> = useMemo(() => {
        return {"ko": ko, "en": en};
    }, []);

    return (
        <IntlProvider locale={i18nStore.language} messages={messages[i18nStore.language]}>
            {props.children}
        </IntlProvider>
    )
};

export default I18nBoundary