import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";


class PermissionListStore {
    @observable
    data: string[] = [];

    @observable
    state: StoreState = 'none';

    constructor() {
        makeObservable(this);
    }

    @action
    async load(channelId: string) {
        this.state = 'pending';
        try {
            const {data} = await axios.get(`${CHATBOT_CMS_HOST}/channels/${channelId}/permissions`, {withCredentials: true});
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
    }
}

export default PermissionListStore;