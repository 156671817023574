import {action, computed, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import {Channel} from "../model/channel"
import channelRepository from "../repository/ChannelRepository"


class ChannelListStore {
    @observable
    data: Channel[];

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        this.data = [];
        makeObservable(this);
    }

    @action
    async load() {
        this.state = 'pending';
        try {
            const {data} = await channelRepository.find();
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @computed
    get channelIds() {
        return this.data.map(channel => channel._id)
    }

    @action
    clear() {
        this.data = [];
        this.state = 'none';
    }
}

export default ChannelListStore;