import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import ChatbotTemplateRepository from "../repository/ChatbotTemplateRepository";
import {Template} from "../model/template";

class ChatbotTemplatesStore {
    @observable
    data: Template[] = [];

    @observable
    state: StoreState = 'none';

    constructor() {
        makeObservable(this);
    }

    @action
    async load() {
        this.state = 'pending';
        try {
            const {data} = await ChatbotTemplateRepository.find();
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = []
    }
}

export default ChatbotTemplatesStore;