import {action, computed, makeObservable, observable} from "mobx";
import {Channel} from "../model/channel"
import {StoreState} from "../model/common"
import channelRepository from "../repository/ChannelRepository"

class ChannelStore {
    @observable
    data?: Channel;

    @observable
    state: StoreState;

    @observable
    template: string = "";

    constructor() {
        this.state = 'none';
        makeObservable(this);
    }

    @action
    set(channel: Channel) {
        this.data = channel;
        this.state = 'done'
    }

    @action
    setTemplate(template: string) {
        this.template = template;
    }

    @action
    async load(channelId: string, disableState?: boolean) {
        if (!disableState) {
            this.state = 'pending';
        }
        try {
            const {data} = await channelRepository.read(channelId);
            this.data = data.result;
            if (!disableState) {
                this.state = 'done';
            }
        } catch (e) {
            this.state = 'error';
            throw e
        }
    }

    @action
    async refresh() {
        try {
            if (this.data) {
                const {data} = await channelRepository.read(this.data._id);
                this.data = data.result;
            }
        } catch (e) {
            this.state = 'error';
            throw e
        }
    }

    @action
    clear() {
        this.data = undefined;
        this.state = 'none'
    }

    @computed
    get scenarioId() {
        return this.data?.config.integration?.conversationMaker.scenarioId;
    }

    @computed
    get liveChatChannelId() {
        return this.data?.config.integration?.liveChat.channelId;
    }
}

export default ChannelStore;