import loadable from "@loadable/component";


export const notFoundRedirectView = loadable(() => import("./NotFoundRedirectView"));

export const channelMainView = loadable(() => import("./ChannelMainView"));

export const createChannelView = loadable(() => import("./channel/CreateChannelView"));
export const channelListView = loadable(() => import("./channel/ChannelListView"));

export const cafeLinkageView =  loadable(() => import("./cafe/CafeLinkageView"));
export const cafeAuthView = loadable(() => import("./cafe/CafeAuthView"));
export const cafeIntroduceView = loadable(() => import("./cafe/CafeIntroduceView"));
export const cafeTokenView = loadable(() => import("./cafe/CafeTokenView"));
export const cafeLinkageFinishView = loadable(() => import("./cafe/CafeLinkageFinishView"));