import {action, makeObservable, observable} from "mobx";
import {LoginUser} from "../model/user";
import {StoreState} from "../model/common";
import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";


class LoginUserStore {
    @observable
    data?: LoginUser;

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        makeObservable(this);
    }

    @action
    async load() {
        this.state = 'pending';
        try {
            const {data} = await axios.get(`${CHATBOT_CMS_HOST}/profile`, {withCredentials: true});
            this.data = data;
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @action
    clear() {
        this.data = undefined;
        this.state = 'none';
    }
}

export default LoginUserStore;