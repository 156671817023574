import {action, computed, makeObservable, observable} from "mobx";
import {cafe24LinkData} from "../model/cafe";
import {StoreState} from "../model/common";
import base64 from "react-native-base64";


class LinkDataStore {
    @observable
    data?: cafe24LinkData;

    @observable
    storageKey: string = base64.encode("cafe24-42maru-mall");

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        makeObservable(this);
    }

    @action
    loadStorage() {
        this.state = 'pending';
        let sessionValid = true;
        let cafe24LinkData

        try {
            cafe24LinkData = JSON.parse(String(sessionStorage.getItem(this.storageKey)));
        } catch {
            sessionValid = false
        }

        if (sessionValid &&
            cafe24LinkData &&
            'userId' in cafe24LinkData &&
            'userName' in cafe24LinkData &&
            'shopNo' in cafe24LinkData) {

            this.data = cafe24LinkData
            this.state = 'done';

        } else {

            this.state = 'error';
            throw Error("Failed to load session")

        }
    }

    @action
    setStorage(user: cafe24LinkData) {
        this.state = 'pending';
        try {
            this.data = user;
            sessionStorage.setItem(
                this.storageKey, String(JSON.stringify(this.data))
            );
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
        }
    }

    @action
    delStorage() {
        this.state = 'pending';
        try {
            sessionStorage.removeItem(this.storageKey);
            this.state = 'done';
        } catch (e) {
            this.state = 'error';
            throw e;
        }
    }

    @computed
    get mallId() {
        return this.data ? String(`${this.data.mallId}`) : undefined
    }

    @computed
    get issueState() {
        if (this.data) {
            const stateKey = `[42maru]${this.data.mallId}@TOKEN`;
            return String(base64.encode(stateKey))
        } else {
            return undefined
        }
    }

    @computed
    get shopNo() {
        return this.data ? Number(`${this.data.shopNo}`) : undefined
    }

    @action
    clear() {
        this.data = undefined;
        this.state = 'none';
    }
}

export default LinkDataStore;