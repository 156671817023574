import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";

class ChatbotTemplateRepository {

    async find() {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/chatbot-templates`, {withCredentials: true});
    }

    async updateDefaultTemplate(channelId: string, templateKey: string, chatbotName: string) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/chatbot/template/default`,
            {
                key: templateKey,
                name: chatbotName
            }, {withCredentials: true});
    }
}

export default new ChatbotTemplateRepository();