import {Spinner} from "react-bootstrap";
import React from "react";

const PageLoading = () => {
    return (
        <div className="page-loading vh-100 d-flex flex-column justify-content-center align-items-center text-center">
            <div>
                <Spinner animation={"border"} style={{width: "40px", height: "40px"}}/>
            </div>
        </div>
    )
}

export default PageLoading