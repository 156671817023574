import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";
import {ChannelCreate, ChannelUpdate} from "../model/channel"

class ChannelRepository {

    async read(channelId: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}`, {withCredentials: true});
    }

    async existed(channelName: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/existed`, {
            withCredentials: true,
            params: {channelName}
        });
    }

    async find() {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels`, {withCredentials: true});
    }

    async create(channelCreate: ChannelCreate) {
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels`, channelCreate, {withCredentials: true});
    }

    async update(channelId: string, channelUpdate: ChannelUpdate) {
        return axios.put(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}`, channelUpdate, {withCredentials: true});
    }

    async readConfig(channelId: string, configName: string) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/configs/${configName}`, {withCredentials: true});
    }

    async uploadAvatar(channelId: string, file: File) {
        let form = new FormData();
        form.append('file', file);
        return axios.post(`${CHATBOT_CMS_HOST}/v1/channels/${channelId}/avatar`, form, {withCredentials: true});
    }
}

export default new ChannelRepository();