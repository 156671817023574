import React, {useEffect, useMemo, useState} from 'react';
import {Route, Switch, useLocation} from "react-router-dom";
import {observer} from "mobx-react-lite"
import * as views from "./view"
import styled from "styled-components";
import I18nBoundary from "./hook/useI18nBoundary";
import ErrorBoundary, {useErrorBoundary} from "./hook/useErrorBoundary";
import NotificationContainer, {useNotification} from "./hook/useNotification";
import PageLoading from "./component/PageLoading"
import Dialog from "./hook/useDialog";
import useStores from "./hook/useStores";
import queryString from "query-string";
import cafe24TokenRepository from "./repository/Cafe24TokenRepository"
import {CHATBOT_CONNECTOR_HOST} from "./core/variables";


const AppWrapper: React.FC = () => {
    const location = useLocation()
    const queries = useMemo(() => {
        return queryString.parse(location.search);
    }, [location.search]);

    const [state, setState] = useState<'NULL' | 'SERVICE'>( queries.mall_id ? 'NULL' : 'SERVICE')
    useEffect(() => {
        if (queries.mall_id) {
            cafe24TokenRepository.mallContent(queries.mall_id as string).then(response => {
                if (response.data.result === 'NOT_SUPPORTED') {
                    window.location.href = `${CHATBOT_CONNECTOR_HOST}/not-supported`
                } else {
                    setState('SERVICE')
                }
            })
        }
    }, [queries])


    if (state === 'NULL') {
        return null
    } else {
        return (
            <AppStyle>
                <I18nBoundary>
                    <ErrorBoundary>
                        <App/>
                    </ErrorBoundary>
                </I18nBoundary>
            </AppStyle>
        );
    }
};

const App: React.FC = observer(() => {
    const location = useLocation();
    const notification = useNotification();
    const errorBoundary = useErrorBoundary();
    const {linkDataStore} = useStores();

    const queries = useMemo(() => {
        return queryString.parse(location.search);
    }, [location.search]);

    useEffect(() => {
        try {
            if(queries.mall_id && queries.user_id && queries.user_name && queries.shop_no){
                const user = {
                    mallId: String(queries.mall_id),
                    userId: String(queries.user_id),
                    userName: String(queries.user_name),
                    shopNo: String(queries.shop_no)
                };

                linkDataStore.setStorage(user);
            } else{
                linkDataStore.loadStorage();
            }
        } catch {
            errorBoundary.setStatus(401)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorBoundary, linkDataStore, notification]);

    if (['none', 'pending'].includes(linkDataStore.state)) {
        return <PageLoading/>
    }

    return (
        <>
            <Switch>
                <Route exact path={"/"}>
                    <views.cafeTokenView />
                </Route>
                {/*When */}
                <Route exact path={'/cafe24/auth'}>
                    <views.cafeAuthView />
                </Route>
                <Route exact path={'/cafe24/introduce'}>
                    <views.cafeIntroduceView />
                </Route>
                <Route path={'/channels'}>
                    <views.channelMainView/>
                </Route>

                <Route path={'*'}>
                    <views.notFoundRedirectView />
                </Route>
            </Switch>
            <NotificationContainer/>
            <Dialog/>
        </>
    );
});

const AppStyle = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export default AppWrapper;
