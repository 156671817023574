import React, {useLayoutEffect} from "react";
import {action, makeObservable, observable} from "mobx";
import {observer} from "mobx-react-lite";
import {useLocation, Link} from "react-router-dom";
import styled from "styled-components";
import {useIntl} from "react-intl";

class ErrorBoundaryStore {
    @observable
    status?: number;

    constructor() {
        makeObservable(this);
    }

    @action
    setStatus(status?: number) {
        this.status = status;
    }
}

const context = React.createContext(new ErrorBoundaryStore());
export const useErrorBoundary = () => React.useContext(context);

// const errorBgUrl = require("../assets/images/error-bg.svg").default;
const startManUrl = require("../assets/images/startman.svg").default;
const textLogo = require("../assets/images/swing_chat_white_en_logo.png").default;


const ErrorBoundary: React.FC = observer(({children}) => {
    const errorBoundary = useErrorBoundary();
    const location = useLocation();
    const intl = useIntl();

    useLayoutEffect(() => {
        errorBoundary.setStatus(undefined);
    }, [errorBoundary, location.pathname]);

    if (errorBoundary.status === 404) {
        return (
            <ErrorViewWrapper>
                <div className="error-content">
                    <div className="row justify-content-center">
                        <div className="">
                            <div className="card">
                                <div className="card-header pt-3 pb-3 text-center bg-primary">
                                    <Link to='/' className='d-flex align-items-center justify-content-center'>
                                        <img src={textLogo} alt="" height="45"/>
                                    </Link>
                                </div>

                                <div className="card-body p-4">

                                    <div className="text-center mb-3">
                                        <img src={startManUrl} height="120" alt="File not found"/>

                                        <h1 className="text-error mt-4">404</h1>
                                        <h4 className="text-uppercase text-danger mt-3 mb-0">
                                            {intl.formatMessage({id: 'i00003'})}
                                        </h4>

                                        <Link to='/' className='btn btn-primary mt-3'>
                                            <i className="mdi mdi-reply mr-1"/>
                                            HOME
                                        </Link>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ErrorViewWrapper>
        );
    }
    else if (errorBoundary.status === 401) {
        return (
            <ErrorViewWrapper>
                <div className="error-content">
                    <div className="row justify-content-center">
                        <div className="">
                            <div className="card">
                                <div className="card-header pt-3 pb-3 text-center bg-primary">
                                    <Link to='/' className='d-flex align-items-center justify-content-center'>
                                        <img src={textLogo} alt="" height="45"/>
                                    </Link>
                                </div>

                                <div className="card-body p-4">

                                    <div className="text-center mb-3">
                                        <img src={startManUrl} height="120" alt="File not found"/>

                                        <h1 className="text-error mt-4 text-secondary">401</h1>
                                        <h4 className="text-uppercase text-secondary mt-3 mb-0">
                                            {intl.formatMessage({id: 'i00204'})}
                                        </h4>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ErrorViewWrapper>
        );
    } else if (errorBoundary.status === 407) {
        return (
            <ErrorViewWrapper>
                <div className="error-content">
                    <div className="row justify-content-center">
                        <div className="">
                            <div className="card">
                                <div className="card-header pt-3 pb-3 text-center bg-primary">
                                    <Link to='/' className='d-flex align-items-center justify-content-center'>
                                        <img src={textLogo} alt="" height="45"/>
                                    </Link>
                                </div>

                                <div className="card-body p-4">

                                    <div className="text-center mb-3">
                                        <img src={startManUrl} height="120" alt="File not found"/>
                                        <h1 className="text-error mt-4 text-secondary">401</h1>
                                        <h4 className="text-uppercase text-secondary mt-3 mb-0">
                                            {intl.formatMessage({id: 'i00212'})}
                                        </h4>
                                        <h4 className="text-uppercase text-secondary mb-0">
                                            {intl.formatMessage({id: 'i00213'})}
                                        </h4>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ErrorViewWrapper>
        )
    } else if (errorBoundary.status === 408) {
        return (
            <ErrorViewWrapper>
                <div className="error-content">
                    <div className="row justify-content-center">
                        <div className="">
                            <div className="card">
                                <div className="card-header pt-3 pb-3 text-center bg-primary">
                                    <Link to='/' className='d-flex align-items-center justify-content-center'>
                                        <img src={textLogo} alt="" height="45"/>
                                    </Link>
                                </div>

                                <div className="card-body p-4">

                                    <div className="text-center mb-3">
                                        <img src={startManUrl} height="120" alt="File not found"/>

                                        <h4 className="text-uppercase text-secondary mt-3 mb-0">
                                            {intl.formatMessage({id: 'i00209'})}
                                        </h4>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ErrorViewWrapper>
        )
    } else if (errorBoundary.status === 500) {
        return (
            <ErrorViewWrapper>
                <div className="error-content">
                    <div className="row justify-content-center">
                        <div className="">
                            <div className="card">
                                <div className="card-header pt-3 pb-3 text-center bg-primary">
                                    <Link to='/' className='d-flex align-items-center justify-content-center'>
                                        <img src={textLogo} alt="" height="45"/>
                                    </Link>
                                </div>

                                <div className="card-body p-4">

                                    <div className="text-center mb-3">
                                        <img src={startManUrl} height="120" alt="File not found"/>
                                        <h1 className="text-error mt-4 text-secondary">500</h1>
                                        <h4 className="text-uppercase text-secondary mt-3 mb-0">
                                            {intl.formatMessage({id: 'i00214'})}
                                        </h4>
                                        <h4 className="text-uppercase text-secondary mb-0">
                                            {intl.formatMessage({id: 'i00215'})}
                                        </h4>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ErrorViewWrapper>
        )
    } else {
        return (<>
            {children}
        </>);
    }
});


const ErrorViewWrapper = styled.div`
  display: flex;
  flex-shrink: 1;
  flex-grow: 0;
  overflow: hidden;
  height: 100%;
  flex-direction: column;
  
  .error-content {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
    min-width: 600px;
    overflow: hidden;
    padding: 5rem;
  }
`;

export default ErrorBoundary
