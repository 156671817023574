import axios from "axios";
import {CHATBOT_CONNECTOR_HOST} from "../core/variables";

class Cafe24TokenRepository {
    async existedAccessToken(mallId: string, state: string) {
        return axios.get(`${CHATBOT_CONNECTOR_HOST}/v1/cafe24/${mallId}/auth/existed`,{
            params: {state},
            headers: {"cafe24-token": state}
        });
    }

    async issueAccessToken(mallId: string, code: string, state: string) {
        return axios.post(`${CHATBOT_CONNECTOR_HOST}/v1/cafe24/${mallId}/auth/token`,
            {
                code: code,
                state: state
            },
            {
                headers: {"cafe24-token": state}
            }
            );
    }

    async mallContent(mallId: string) {
        return axios.get(`${CHATBOT_CONNECTOR_HOST}/v1/cafe24/${mallId}/auth/content`);
    }
}

export default new Cafe24TokenRepository();