import React from "react";
import LoginUserStore from "./LoginUserStore";
import PermissionListStore from "./PermissionListStore";
import LinkDataStore from "./LinkDataStore";
import ChannelListStore from "./ChannelListStore";
import ChannelStore from "./ChannelStore";
import ChatbotTemplateStore from "./ChatbotTemplateStore";
import CafeAuthExistedStore from "./CafeAuthExistedStore";
import ShopIntegrationStore from "./ShopIntegrationStore";
import ChatbotSettingStore from "./ChatbotSettingStore";
import ConnectorAccountsStore from "./ChannelAccountsStore";

export const storeContext = React.createContext({
    loginUserStore: new LoginUserStore(),
    permissionListStore: new PermissionListStore(),
    linkDataStore: new LinkDataStore(),
    cafeAuthExistedStore: new CafeAuthExistedStore(),
    channelListStore: new ChannelListStore(),
    channelStore: new ChannelStore(),
    chatbotTemplateStore: new ChatbotTemplateStore(),
    shopIntegrationStore: new ShopIntegrationStore(),
    chatbotSettingStore: new ChatbotSettingStore(),
    connectorAccountsStore: new ConnectorAccountsStore()
});