const language: Record<string, string> = {
    e00001: "일시적인 오류로 처리할 수 없습니다. \n지속적인 오류 발생시, 고객센터에 문의해 주시기 바랍니다.",

    i00001: '챗봇',
    i00002: '접근 권한이 없습니다.',
    i00003: '존재하지 않는 페이지 입니다.',
    i00004: '전체 챗봇 대화',
    i00005: '대화 흐름',
    i00006: '상담사 메신저 설정',
    i00007: '메시지 설정',
    i00008: '고객이 상담사 연결과 관련해 챗봇이 응답할 메시지를 설정한다.',
    i00009: '상담 요청 메시지',
    i00010: '연결 취소 메시지',
    i00011: '부재중 메시지',
    i00012: '상담 시작 메시지',
    i00013: '상담 종료 메시지',
    i00014: '상담사 설정 정보 조회 중 오류가 발생했습니다.',
    i00015: '상담사 챗봇 메시지를 저장 했습니다.',
    i00016: '상담사 챗봇 메시지 저장 중 오류가 발생했습니다.',
    i00017: '상담 운영시간 설정',
    i00018: '요일별로 상담 운영시간을 설정하세요.\n수동으로도 상담 가능 여부를 설정할 수 있습니다.',
    i00019: '상담 운영시간을 수정했습니다.',
    i00020: '상담 운영시간 수정 중 오류가 발생했습니다.',
    i00021: '일',
    i00022: '월',
    i00023: '화',
    i00024: '수',
    i00025: '목',
    i00026: '금',
    i00027: '토',
    i00028: '상담 운영시간 설정 방법',
    i00029: '수동으로 설정',
    i00030: '요일별 시간 설정',
    i00031: '운영 상태',
    i00032: '운영중',
    i00033: '부재중',
    i00034: '요일',
    i00035: '시간',
    i00036: '추가',
    i00037: '시간대',
    i00038: '카테고리 관리',
    i00039: '상담 카테고리를 설정해서 카테고리 별로 상담을 분류하고 어떤 종류의 상담이 많은지 확인할 수 있습니다.\n상담 분배 설정에서 카테고리 별로 상담원을 배치해 상담을 분배 할 수 있습니다.',
    i00040: '카테고리',
    i00041: '상담수',
    i00042: '상담원 수',
    i00043: '생성일',
    i00044: '등록된 카테고리가 없습니다.',
    i00045: '카테고리 등록 중 에러가 발생했습니다.',
    i00046: '카테고리 만들기',
    i00047: '카테고리명',
    i00048: '해당 카테고리가 이미 존재합니다.',
    i00049: '카테고리를 삭제 하시겠습니까?',
    i00050: '해당 카테고리가 적용된 모든 상담챗에 변경사항이 적용됩니다.',
    i00051: '카테고리 삭제 중 에러가 발생했습니다.',
    i00052: '카테고리 조회 중 에러가 발생했습니다.',
    i00053: '카테고리 수정 중 에러가 발생했습니다.',
    i00054: '카테고리 수정하기',
    i00055: '상담 분배 방식 설정',
    i00056: '순차 및 카테고리 우선 분배 방식으로 상담을 분배 할 수 있습니다.' +
        '\n카테고리 우선 분배는 고객이 상담 요청시 설정한 카테고리를 담당하고 있는 상담사에게 우선 배정됩니다.' +
        '\n해당 카테고리를 담당하고 있는 상담사의 진행, 대기중인 상담이 카테고리 우선 분배시 상담 제한 수 보다 많을 경우에는 원할한 상담을 위해 순차 분배 방식으로 다른 상담사에게 배정됩니다.',
    i00057: '상담 분배 방식 수정 중 에러가 발생했습니다.',
    i00058: '상담 분배 방식을 수정했습니다.',
    i00059: '분배 방식',
    i00060: '순차 분배',
    i00061: '카테고리 우선 분배',
    i00062: '수동 분배',
    i00063: '카테고리 우선 분배시 상담 제한 수',
    i00064: '금칙어 관리',
    i00065: '금칙어를 등록해서 욕설, 선정적인 발언, 비하 발언 등을 마스킹 처리할 수 있습니다.',
    i00066: '금칙어 등록 중 에러가 발생했습니다.',
    i00067: '금칙어 삭제 중 에러가 발생했습니다.',
    i00068: '금칙어를 삭제 하시겠습니까?',
    i00069: '금칙어를 삭제 하면 기존 메시지의 마스킹이 모두 제거 됩니다.',
    i00070: '금칙어',
    i00071: '등록된 금칙어가 없습니다.',
    i00072: '더 많은 설정',
    i00073: '여러 상담사가 사용하는 상담사 메신저는 웹브라우저보다 편리한 별도 프로그램으로 준비 했습니다.\n다운로드를 받아 앱에서 더 많은 설으로 효율적인 상담을 진행해 보세요.',
    i00074: '상담사 앱 실행하기',
    i00075: '상담 채널 설정',
    i00076: '프로필 설정',
    i00077: '멤버 관리',
    i00078: '새로운 멤버를 초대해 보세요.',
    i00079: '상담 프로필을 설정해 보세요.',
    i00080: '상담사 멤버 조회 중 에러가 발생했습니다.',
    i00081: '상담사 멤버 삭제',
    i00082: '상담사 멤버를 삭제하시겠습니까? 삭제한 멤버는 채널에 접근 할 수 없습니다.',
    i00083: '상담사 멤버를 삭제 중 에러가 발생했습니다.',
    i00084: '삭제',
    i00085: '멤버',
    i00086: '관리자',
    i00087: '상담사 멤버 등록 중 에러가 발생했습니다.',
    i00088: '멤버 추가',
    i00089: '이메일',
    i00090: '유형',
    i00091: '이름',
    i00092: '등록된 상담사 멤버가 없습니다.',
    i00093: '에러가 발생했습니다. 다시 불러오기',
    i00094: '멤버 추가',
    i00095: '챗봇',
    i00096: '상담챗',
    i00097: '고객이 챗봇을 어떻게 사용하는지 확인 할 수 있습니다.',
    i00098: '상담 업무가 어떻게 돌아가고 있는지 확인 할 수 있습니다.',
    i00099: '통계',
    i00100: '사진 업로드',
    i00101: '업로드',
    i00102: '채널 로고 등록 중 에러가 발생했습니다.',
    i00103: '채널 주소',
    i00104: '홈페이지',
    i00105: '국가',
    i00106: '시간대',
    i00107: '전화번호',
    i00108: '올바르지 않은 전화번호 입니다.',
    i00109: '채널 프로필 정보 저장 중 에러가 발생했습니다.',
    i00110: '채널 프로필 정보가 수정되었습니다.',
    i00111: '채널 로고가 수정되었습니다.',
    i00112: '상담사',
    i00113: '현재 상담사 메신저는 전용 어플리케이션에서만 지원합니다. 웹에서의 지원은 조금만 기다려주세요.곧 찾아뵙겠습니다.',
    i00114: '상담사 앱 실행하기',
    i00115: '윈도우용 프로그램 다운로드',
    i00116: '맥용 프로그램 다운로드',
    i00117: '이메일',
    i00118: '비밀번호',
    i00119: '로그인',
    i00120: '이메일을 입력해주세요.',
    i00121: '비밀번호를 입력해주세요.',
    i00122: '비밀번호를 잊어버리셨나요?',
    i00123: '회원가입',
    i00124: '아직 회원이 아니신가요?',
    i00125: '이메일을 입력하세요.',
    i00126: '비밀번호를 입력하세요.',
    i00127: '로그인 상태 유지',
    i00128: '로그인을 위해 계정의 이메일과 비밀번호를 입력해주세요.',
    i00129: '이메일 또는 비밀번호가 잘못되었습니다.',
    i00130: '무료 회원가입',
    i00131: '아직 계정이 없으신가요? 잠깐이면 계정을 생성 할 수 있습니다.',
    i00132: '이름',
    i00133: '성',
    i00134: '이름을 입력하세요.',
    i00135: '성을 입력하세요.',
    i00136: '이름을 입력해주세요.',
    i00137: '성을 입력해주세요.',
    i00138: '이미 계정이 있으신가요?',
    i00139: '서비스 이용약관 (필수)',
    i00140: '개인정보 처리 방침 (필수)',
    i00141: '마케팅 메시지 수신 동의 (선택사항)',
    i00142: '동의',
    i00143: '전체 동의',
    i00144: '닫기',
    i00145: '서비스 이용약관',
    i00146: '개인정보 처리 방침',
    i00147: '마케팅 수신 동의 방침',
    i00148: '이용약관을 확인하고 동의해주세요.',
    i00149: '개인정보 처리방침을 확인하고 동의해주세요.',
    i00150: '회원가입 도중 오류가 발생했습니다.',
    i00151: '이미 가입된 이메일입니다.',
    i00152: '이메일을 확인해주세요.',
    i00153: '가입하신 이메일 {email}로 인증메일을 보냈습니다. 계정 확인을 위해서 메일에 있는 링크를 클릭하여 회원가입을 완료하세요.',
    i00154: '만료 또는 변조된 인증정보로 로그인을 시도했습니다.',
    i00155: '비밀번호 초기화',
    i00156: '회원가입시 기입했던 이메일을 입력하세요. 해당 이메일로 비밀번호를 설정할 수 있는 링크를 보내드립니다.',
    i00157: '인증 이메일을 보냈습니다.',
    i00158: '이미 인증받은 계정입니다.',
    i00159: '해당 이메일로 등록된 계정이 없습니다.',
    i00160: '비밀번호 초기화 이메일을 보냈습니다.',
    i00161: '이메일 전송중 오류가 발생했습니다.',
    i00162: '새로운 비밀번호',
    i00163: '새로운 비밀번호를 입력하세요.',
    i00164: '비밀번호 변경',
    i00165: '비밀번호 변경 정보가 잘못 되었거나 만료되었습니다.',
    i00166: '비밀번호 변경중 오류가 발생했습니다.',
    i00167: '새로운 비밀번호를 입력해서 변경하세요.',
    i00168: '비밀번호 변경 완료',
    i00169: '로그인 버튼을 눌러 새로운 비밀번호로 로그인을 하세요.',
    i00170: '혹시 이메일을 받지 못하셨나요?',
    i00171: '애플리케이션 로그인',
    i00172: '{user} 계정으로 로그인 되었습니다. 애플리케이션을 실행하는 동안 잠시만 기다려주세요.',
    i00173: '애플리케이션 로그아웃',
    i00174: '애플리케이션에서 로그아웃 되었습니다. 브라우저에서도 로그아웃을 하려면 로그아웃 버튼을 클릭해주세요.',
    i00175: '애플리케이션에서 로그아웃 되었습니다.',
    i00176: '로그아웃',
    i00177: '알림',
    i00178: '로그아웃 하시겠습니까?',
    i00179: '확인',
    i00180: '채널 초대 수락',
    i00181: '채널의 초대를 수락하시려면 아래 수락 버튼을 눌러주세요.',
    i00182: '초대 수락',
    i00183: '초대 시간이 만료되었거나 초대 링크가 유효하지 않습니다.',
    i00184: '{channel} 채널에서 초대를 받았습니다.',
    i00185: '채널에 초대한 멤버 정보 조회 중 에러가 발생했습니다.',
    i00186: '초대중인 사용자',
    i00187: '초대일시',
    i00188: '에러가 발생했습니다. 다시 불러오기',
    i00189: '초대 메일을 전송했습니다.',
    i00190: '멤버 초대 중 에러가 발생 했습니다.',
    i00191: '초대 메일 재전송',
    i00192: '이메일 형식이 올바르지 않습니다.',
    i00193: '이미 초대한 멤버입니다.',

    i00194: "채널 이름",
    i00195: "채널 이름은 중복될 수 없습니다.",
    i00196: "채널 설명",
    i00197: "저장",
    i00198: "목록으로",
    i00199: "챗봇 종류",
    i00200: "필수 입력 항목입니다.",
    i00201: "시스템 에러",
    i00202: "중복된 이름 입니다.",
    i00203: "취소",
    i00204: "잘못된 접근 방식 입니다. 카페24 앱스토어를 통해서 접근해 주세요.",
    i00205: "채널 생성",
    i00206: "카페24에 연동된 채널이 아닙니다.",
    i00207: "다른 서비스에 연동됨",
    i00208: "카페24에 연동된 채널이 아닙니다.",
    i00209: "다른 계정에 연동된 쇼핑몰입니다. ",
    i00210: "연동됨 {mallId}, {shopNo}번 쇼핑몰 ",
    i00211: "두 개 이상의 채널을 한 쇼핑몰에 연동할 수 없습니다.",
    i00212: "권한이 없습니다.",
    i00213: "'대표 운영자 계정'으로 로그인하신 후 앱스토어를 통해 다시 시도해 주세요.",
    i00214: "현재 설치페이지 접속이 원활하지 못합니다.",
    i00215: "신속한 복구를 위해 노력하겠습니다. 서비스 이용에 불편을 드려 죄송합니다.",

};


export default language;