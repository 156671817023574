import axios from "axios";
import {CHATBOT_CMS_HOST} from "../core/variables";

class Cafe24ShopIntegrationRepository {
    async read(mallId: string, shopNo: number) {
        return axios.get(`${CHATBOT_CMS_HOST}/v1/cafe24/${mallId}/shops/${shopNo}/integration`,
            {withCredentials: true}
        );
    }
}

export default new Cafe24ShopIntegrationRepository();