import axios from "axios";
import {CHATBOT_CONNECTOR_HOST} from "../core/variables";

class ConnectorAccountRepository {

    async findChannelAccounts(accountIds: string[]){
        return axios.get(`${CHATBOT_CONNECTOR_HOST}/v1/accounts`, {
            params: {accountIds: accountIds.join(',')}
        })
    }

    async createDomainLink(props: {accountId: string, domainLink: Object}){

        return axios.post(
            `${CHATBOT_CONNECTOR_HOST}/v1/accounts/${props.accountId}/domain-link`,
            props.domainLink
        )
    }

    async clearDomainLink(accountId: string){
        return axios.delete(`${CHATBOT_CONNECTOR_HOST}/v1/accounts/${accountId}/domain-link`)
    }
}

export default new ConnectorAccountRepository();