import {action, makeAutoObservable, observable} from "mobx";
import React from "react";
import {Button, Modal} from 'react-bootstrap';
import {observer} from "mobx-react-lite";
import classNames from "classnames";
import {useIntl} from "react-intl";

class DialogStore {

    @observable
    isOpen: boolean = false;

    @observable
    title: string | null = null;

    @observable
    content: string | null = null;

    @observable
    variant: DialogVariant = 'primary';

    @observable
    confirmText?: string;

    @observable
    cancelText?: string;

    @observable
    onConfirm: VoidFunction | null = null;

    @observable
    onCancel: VoidFunction | null = null;

    @observable
    hideConfirm: boolean = false;

    constructor() {
        makeAutoObservable(this)
    }

    @action
    open(params: OpenParams) {
        this.isOpen = true;
        this.content = params.content;
        this.onConfirm = params.onConfirm || null;
        this.title = params.title || 'Alert';
        this.variant = params.variant || 'primary';
        this.confirmText = params.confirmText;
        this.cancelText = params.cancelText;
        this.onCancel = params.onCancel || null;
        this.hideConfirm = params.hideConfirm || false;
    }

    @action
    close() {
        this.isOpen = false;
        setTimeout(() => {
            this.content = null;
            this.onConfirm = null;
            this.title = null;
            this.variant = 'primary';
            this.confirmText = undefined;
            this.cancelText = undefined;
            this.onCancel = null;
            this.hideConfirm = false;
        }, 300);
    }
}

type DialogVariant = 'primary' | 'info' | 'success' | 'warning' | 'danger';

type OpenParams = {content: string, onConfirm?: VoidFunction, title?: string, variant?: DialogVariant, confirmText?: string, cancelText?: string, onCancel?: VoidFunction, hideConfirm?: boolean};

const context = React.createContext(new DialogStore());
export const useDialog = () => React.useContext(context);

const Dialog: React.FC = observer(() => {

    const dialog = useDialog();
    const intl = useIntl();

    const handleClose = () => {
        if (dialog.onCancel) {
            dialog.onCancel();
        }

        dialog.close();
    };

    const onClickConfirmBtn = (e: React.MouseEvent) => {
        e.preventDefault();

        if (dialog.onConfirm) {
            dialog.onConfirm();
        }

        dialog.close();
    };

    return (
        <Modal show={dialog.isOpen} onHide={handleClose} animation={true} centered>
            {/*<Modal.Header className={`modal-colored-header bg-${dialog.variant} pt-2 pb-2`} closeButton>*/}
            {/*    <Modal.Title as={'h5'}>{dialog.title}</Modal.Title>*/}
            {/*</Modal.Header>*/}
            <Modal.Body>
                <div className="text-center">
                    <h4 className="mb-4 text-primary">{dialog.title}</h4>

                    <p style={{whiteSpace: 'pre-wrap'}}>{dialog.content}</p>
                </div>

                <div className="text-center mt-3">
                    <Button
                        className={classNames({'d-none':dialog.hideConfirm} )}
                        variant={dialog.variant}
                        style={{height: "2.2rem", width: "11rem"}}
                        onClick={onClickConfirmBtn}>
                        {dialog.confirmText || intl.formatMessage({id: 'i00179'})}
                    </Button>
                </div>
                <div className="text-center mt-1">
                    <span className="font-14 text-primary" onClick={handleClose} style={{cursor: "pointer"}}>
                        {dialog.cancelText || intl.formatMessage({id: 'i00203'})}
                    </span>
                    <span className="uil-arrow-up-right font-weight-bold font-18 text-primary"/>
                </div>
            </Modal.Body>
        </Modal>
    );
});

export default Dialog;