import {action, makeObservable, observable} from "mobx";
import {StoreState} from "../model/common";
import cafe24ShopIntegrationRepository from "../repository/Cafe24ShopIntegrationRepository";


class ShopIntegrationStore {
    @observable
    data?: {
        mallId?: string
        channelId: string
        userId?: string
        userName?: string
        shopNo?: number
    };

    @observable
    state: StoreState;

    constructor() {
        this.state = 'none';
        makeObservable(this);
    }

    @action
    async load(mallId: string, shopNo: number) {
        this.state = 'pending';
        try {
            const {data} = await cafe24ShopIntegrationRepository.read(mallId, shopNo);
            this.data = data.result;
            this.state = 'done';
        } catch (e) {
            if (e.response?.status === 404) {
                this.state = 'done';
            } else {
                this.state = 'error';
                throw e;
            }
        }
    }

    @action
    clear() {
        this.data = undefined;
        this.state = 'none';
    }
}

export default ShopIntegrationStore;